import { useRegionStore } from '@/store/region'
import { useProductStore } from '@/store/product'
import { useBranchStore } from '@/store/branch'
import { useRegionChecker } from '@/composables/useRegion'

/**
 * 檢查是否為 Niceday 的 region，如果不是，則強制導向 Niceday 的 region
 *
 */

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $i18n, $pinia } = useNuxtApp()

  const regionStore = useRegionStore($pinia)
  const productStore = useProductStore($pinia)
  const branchStore = useBranchStore($pinia)

  // cannot use "template string", because the type of "to.name" may be symbol
  const toPageName = to.name?.toString() || ''
  const isToProductPage = /products-pid/.test(toPageName)
  const isToBranchPage = /branches-bid/.test(toPageName)
  const isRegionPage = /^regions-rid/.test(toPageName)

  const fetchRegionList = () => regionStore.fetchRegionList()
  const fetchRegionDetect = () => regionStore.fetchRegionDetect()
  const fetchProductSaleRegions = () => productStore.fetchSaleRegionsById(`${to.params.pid}`)
  const fetchBranchSaleRegions = () => branchStore.fetchSaleRegionsById(`${to.params.bid}`)
  const apiRequests = [
    ...(!regionStore.region?.region_id ? [fetchRegionList()] : []),
    ...(!regionStore.regionDetectInfo ? [fetchRegionDetect()] : []),
    ...(isToProductPage ? [fetchProductSaleRegions()] : []),
    ...(isToBranchPage ? [fetchBranchSaleRegions()] : []),
  ]

  if (apiRequests.length > 0) {
    await Promise.all(apiRequests)
  }

  const currentLangCode = $i18n.locale.value

  const { detectValidLang, getSaleRegionInfo } = useRegionChecker()

  const storeRegionIds = regionStore.regionList.map(region => region.region_id)
  const detectedRegionId = regionStore.regionDetectInfo?.id
  const routeRegionId = Number(to.params.rid)
  const fromRegionId = Number(from.params.rid)
  const isRouteRegionIdValid = storeRegionIds.includes(routeRegionId)
  const isFromRegionIdValid = storeRegionIds.includes(fromRegionId)

  const validRegionId = (() => {
    if (isToProductPage || isToBranchPage) {
      const { validSaleRegionId } = getSaleRegionInfo({ isToProductPage, routeRegionId }, { $pinia })

      // 若該 product/branch 無銷售區域，則直接抓使用者的位置 region id。
      return validSaleRegionId || detectedRegionId
    }
    if (!isRouteRegionIdValid && !isFromRegionIdValid) {
      return regionStore.currentRegionId || detectedRegionId
    }

    return routeRegionId || fromRegionId
  })()

  if (regionStore.currentRegionId !== validRegionId) {
    regionStore.currentRegionId = validRegionId
  }

  const { isCurrentLangValid, supportedLang } = detectValidLang(currentLangCode, { $pinia })

  if (isToProductPage || isToBranchPage) {
    const { isSaleRegionsEmpty, isRegionInSale } = getSaleRegionInfo({ isToProductPage, routeRegionId })

    /**
     * 若該 product/branch 無銷售區域，且當前的 path 皆有效，則不做任何處理，
     * 否則往下執行，導頁到合法的語言與 region id。
     */
    if (isSaleRegionsEmpty && isRouteRegionIdValid && isCurrentLangValid) {
      return
    }
    // 若當前的 path 皆是有效的，則不做任何處理。
    if (isRegionInSale && isCurrentLangValid) {
      return
    }

    const pageType = isToProductPage ? 'products' : 'branches'
    const pageId = isToProductPage ? to.params.pid : to.params.bid
    const routeRaw = { path: `/${supportedLang}/regions/${regionStore.currentRegionId}/${pageType}/${pageId}`, query: to.query, hash: to.hash }

    return navigateTo(routeRaw, { replace: true })
  }

  if (isRegionPage && (!storeRegionIds.length || !isRouteRegionIdValid)) {
    const basePath = (() => {
      return `/regions/${detectedRegionId}`
    })()

    return navigateTo(`/${supportedLang}${basePath}`, { replace: true })
  }

  if (!isCurrentLangValid) {
    const stripLangPrefixPath = to.fullPath.replace(/\/[^/]+/, '')

    return navigateTo(`/${supportedLang}${stripLangPrefixPath}`, { replace: true })
  }
})
